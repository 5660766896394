import React from 'react';
import SEO from '../components/SEO';

import SwitchCalculator from '../components/SwitchCalculator';

export default function ({}) {
  return (
    <>
      <SEO
        title={'Switch Calculator'}
        description={'In a contract? Check if you are able to switch early.'}
        brand={'sc'}
      />

      <SwitchCalculator />
    </>
  );
}
